$mainTextColor: #707070;
$mainBackgroundColor: #F9F9F9;
$mainBackgroundSecondaryColor: $mainTextColor;
$disabledColor: #dddddd;

/* Primary and Secondary accent color for your app */
$primaryColor: #DCEBF7;
$primaryTextColor: #707070;
$secondaryColor: #007ADE;
$quaternaryColor: #74a0e3;
$tertiaryColor: #c4deff;
/* =============================================== */

$infoTextColor: #0072bb;
$infoBackgroundColor: #07CCD6;

$successTextColor: #006e57;
$successBackgroundColor: #a9e2da;

$warningTextColor: #a99700;
$warningBackgroundColor: #f6f2bd;

$errorTextColor: #ca1829;
$errorBackgroundColor: #ffccd3;

$formattingFilterColor: #ffd580;
$formattingFilterColorOld: #ff9f96;

$wildcardFilterColor: #bc6803;
