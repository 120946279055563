@import "./palette";
@import "./sizes";
@import "./forms";

html, body { height: 100%; }
body { color: $primaryTextColor; margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.spacer {
  display: flex;
  flex-grow: 1;
}

h1, h2, h3, h4, h5 {
  color: $mainTextColor;
  display: block;
}

h1 { font-size: $h1Size; }
h2 { font-size: $h2Size; }
h3 { font-size: $h3Size; }
h4 { font-size: $h4Size; }
h5 { font-size: $h5Size; }

p {
  color: $mainTextColor;
}

span {
  display: inline-block;
}

small {
  display: inline-block;
  color: $mainTextColor;
  font-size: $textSize * 0.8;
}

a {
  display: inline-block;
  color: $secondaryColor;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.3s ease-in;
  cursor: pointer;

  :hover { opacity: 0.7; }
  :visited { color: $tertiaryColor; }
}

code {
  background: $mainBackgroundSecondaryColor;
  font-family: 'monospace';
  color: $mainTextColor;
  padding: $padding;
}

ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: $listMargin;
  }
}

i.fa {
  display: inline-block;
  padding: 0 $padding;
}

.transparent { border: none; outline: none; background: transparent; }

.modal-dialog-actions{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $paddingContent!important;
}

textarea:focus, input:focus{
  outline: none;
}

/* Table Actions */
.table-action-delete {
  color: $errorTextColor;
}

/* MATERIAL BUTTONS */
/* We set style based on our palette */

button.mat-primary, a.mat-primary {
  color: $primaryTextColor!important;
  cursor: pointer!important;
}
button.mat-accent, a.mat-accent {
  color: $primaryTextColor!important;
  background-color: $errorBackgroundColor!important;
  cursor: pointer!important;
}
button.mat-warn, a.mat-warn {
  color: $warningTextColor;
  background-color: $warningBackgroundColor;
  cursor: pointer!important;
}

button.mat-disabled, a.mat-disabled {
  color: $mainTextColor;
  background-color: $disabledColor;
  cursor: pointer!important;
}

.mat-mdc-button {
  display: inline-flex;
  cursor: pointer!important;
}


/* MATERIAL SNACKBAR */
/* We set style based on our palette */
.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background-color: transparent!important;
  }

  &.app-notification-info {
    background-color: $infoBackgroundColor!important;
    div, button { color: $infoTextColor!important; }
  }

  &.app-notification-warning {
    background-color: $warningBackgroundColor;
    div, button { color: $warningTextColor!important; }
  }

  &.app-notification-error {
    background-color: $errorBackgroundColor;
    div, button { color: $errorTextColor!important; }
  }

  &.app-notification-success {
    background-color: $successBackgroundColor;
    div, button { color: $successTextColor!important; }
  }
}

/* MATERIAL SPINNER */
/* We set the style for the material spinner indicator */
mat-spinner {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*Material menu panel item*/
/*Added padding and increased font size*/
.mat-mdc-menu-item {
  padding-right: $paddingContent!important;
  padding-left: $paddingContent!important;
  i, span {
    font-size: $h4Size!important;
  }
}

/*Material menu panel*/
/*make panel appear from the right*/
.mat-mdc-menu-panel:not(.table-action-menu) + .cdk-overlay-connected-position-bounding-box {
  align-items: flex-end!important;
}

/*navbar profile menu*/
.mat-mdc-menu-panel.profile-mat-menu {
  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 20px;
    .mat-mdc-menu-item {
      span {
        margin-right: 10px;
      }
      .mdc-list-item__primary-text {
        width: 100%;
        justify-content: space-between;
        display: flex;
      }
    }
    i {
      font-size: 20px!important;
    }
    mat-divider {
      width: 100%;
    }
  }
}

/*Material input icon*/
/*make suffix not hug right side of input*/
.mat-mdc-form-field-icon-suffix{
  padding-right: $padding!important;
}
::ng-deep.mat-mdc-option {
  font-size: 14px;
}

.custom-download-snackbar {
  background-color: $tertiaryColor!important;
  .mat-mdc-snack-bar-label {
    color: black;
  }
  .mat-mdc-button {
    color: black!important;
  }
}
